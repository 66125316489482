import React, { Component } from 'react';
import './NoMatch.css';

import {
  //BrowserRouter as Router,
  //Switch,
  //Route,
  Link
} from "react-router-dom";

function Header({ children }) {
  return <h2>{children}</h2>;
}

class NoMatch extends Component {
  render() {
    return (
      <div className="NoMatch">

        <Header as='h2'>
          Page Not Found
          <Header>
          <Link to="/">Try Home</Link>
          </Header>

        </Header>

        
      </div>
    );
  }
}

export default NoMatch;

import React, { Component } from 'react';
import './Router.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Menu from './Menu.jsx';
import Home from './Home.jsx';
import Projects from './Projects.jsx';
import NoMatch from './NoMatch.jsx';

class Main extends Component {

  render() {
    return (
      <Router>
        <div>
          <Menu />

          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route path="/projects">
              <Projects />
            </Route>

            {/* <Route path="/projects/nagiostv">
              <div style={{ margin: 20 }}>
                NagiosTV
              </div>
            </Route>

            <Route path="/projects/:projectName">
              <div style={{ margin: 20 }}>
                Specific Project
              </div>
            </Route> */}

            <Route path="/blog">
              <div style={{ margin: 20 }}>
                Blog
              </div>
            </Route>
            <Route path="/resume">
              Resume
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Main;

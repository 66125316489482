import {
  //BrowserRouter as Router,
  //Switch,
  //Route,
  Link
} from "react-router-dom";

import Doomguy from '../doomguy/doomguy';

function Header({ children }) {
  return <h3 className="Header">{children}</h3>;
}

function Segment({ children, style }) {
  return <div className="Segment" style={style}>{children}</div>;
}

function Project({ name, subheader, children, style }) {
  return (
    <Segment inverted style={style}>
      <div>
        <Header>
          {name}
        </Header>
        {subheader}
      </div>
      {children}
    </Segment>
  );
}

const ProjectsIndex = () => {
  return (
    <div>
      <Header as='h2'>
        Projects
      </Header>

      <Project
        name="NagiosTV"
        subheader="NagiosTV is a user interface (UI) for the Nagios monitoring system"
      >
        <div style={{ position: 'relative', top: '-60px', float: 'right' }}>
          <img loading="lazy" src="/images/nagiostv-zoom.png" alt="NagiosTV" /><br />
        </div>

        <div>
          <a target="_blank" rel="noopener noreferrer" href="https://nagiostv.com">NagiosTV.com</a><br />
          Open Source<br />
          2008-current
        </div>
      </Project>

      <Project
        name="iPhone UI in React"
        subheader="Try to copy the iPhone UI in React"
      >
        <div style={{ position: 'relative', top: '-60px', float: 'right' }}>
          {/* <img loading="lazy" src="/images/nagiostv-zoom.png" alt="NagiosTV" /><br /> */}
        </div>
        <a href="https://chriscarey.com/projects/iphone-ui/">iPhone UI in React Demo</a>

      </Project>

      

      <Project
        name="FlynnCSS"
        subheader="Flynn from Doom as a CSS sprite"
        style={{ minHeight: '150px' }}
      >
        <div style={{ position: 'relative', top: '-60px', float: 'right' }}>
        <Doomguy />
        </div>
        <a href="https://github.com/chriscareycode/doom-flynn-css">Check it out at GitHub</a>
      </Project>

      <Project
        name="ImageToHTML"
        subheader="Convert an image to HTML"
      />

      <Project
        name="UtahFM for iPhone"
        subheader="UtahFM"
      />

      <Project
        name="SLUG Magazine for iPhone"
        subheader="SLUG Magazine"
      />

      <Project
        name="Asterisk Visual Voicemail"
        subheader="Visual Voicemail web interface for the Asterisk phone system"
      >
        
      </Project>

      <Project
        name="MythTV for iPhone"
        subheader="Watch MythTV recorded shows on iPhone"
      >
        <div>
          <a target="_blank" rel="noreferrer noopener" href="http://chriscarey.com/projects/mythtv/iphone/">Webpage</a><br />
          <a target="_blank" rel="noreferrer noopener" href="https://www.youtube.com/watch?v=m21jlkiV9oI 0.01">YouTube v0.01</a><br />
          <a target="_blank" rel="noreferrer noopener" href="https://www.youtube.com/watch?v=y-l6tMWlzYY 0.03">YouTube v0.03</a><br />
        </div>
      </Project>
      
      <Project
        name="Asterisk Voicemail for iPhone"
        subheader="Like iPhone visual voicemail but for the Asterisk open source phone system"
      >
        <div>
          <a target="_blank" rel="noreferrer noopener" href="https://chriscarey.com/projects/asterisk/iphone/">Webpage</a><br />
          Featured in TrixBox Linux distribution

        </div>
      </Project>

      <Project
        name="PR: thc-hydra add support for HTTP HEAD for WRT54G and more"
        subheader=""
      >
        <div>
        Submit a PR to thc-hydra project to allow it to brute force with HTTP method HEAD (previously it only supported GET AND POST).
        This technique was needed for the software to successfully work against the popular Linksys WRT54G.

        </div>
        <br />
        2006
      </Project>

      <Project
        name="YummiYogurt WinAMP skin"
        subheader="Featured in WIRED Magazine Oct 2000"
      >
        <img loading="lazy" src="/images/yummiyogurt2001xmms-275x372.gif" alt="YummiYogurt 2001 screenshot" />
        <img loading="lazy" src="/images/YummiYogurt.gif" style={{ marginLeft: 20 }} alt="YummiYogurt screenshot" />
        <br />
        <br />
        TODO: WinAMP dropped all skins from their site. See if they moved, or point to Internet Archive<br />
        Website: <a target="_blank" rel="noreferrer noopener" href="https://chriscarey.com/blog/design/skins/yummiyogurt/">https://chriscarey.com/blog/design/skins/yummiyogurt/</a><br />
        
      </Project>

      <Project
        name="Control Panel Control"
        subheader="Software for Windows 95/98/ME (Released in 1998)"
      >
        <div>
          <img loading="lazy" src="/images/cpcontrol.jpg" alt="Control Panel Control screenshot" /><br />
          Control Panel Control allows you to access your Control Panels quicker. Control Panel Control places a drop-down Control Panels Folder in commonly used places, such as your Start Menu, Desktop, or your Favorites folder.
        </div>
      </Project>

      <Project
        name="SublimeSpot"
        subheader="Fan site for the band Sublime. A tribute to Brad Nowell."
      >
        <div>First Sublime site on the Internet. #1 result on Google for search term "sublime" for 10+ years 1995 - 2006</div>
        <br />
        <div>Name in the &quot;Thank You&quot; section of the multi-platnum self-title album CD cover.</div>
      </Project>

      {/* <div>
        <Link to="/projects">Go back to projects</Link>
      </div> */}

    </div>
  );
};
export default ProjectsIndex;
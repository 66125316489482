import React, { useEffect } from 'react';
import {
  //BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ProjectsIndex from './Projects/ProjectsIndex';
import './Projects.css';
//import './main.css';
// import Flynn from './flynn/flynn.jsx';
// import SublimeSpot from './sections/sublimespot.jsx';
// import Blog from './sections/blog.jsx';
// import NagiosTVNagios4 from './sections/nagiostv-4.jsx';
// import NagiosTV from './sections/nagiostv.jsx';
// import FlynnCSS from './sections/flynncss.jsx';



export default function Projects() {

  useEffect(() => {
    document.title = 'Chris Carey Projects';
  }, []);

  return (
    <div className="Projects">

      <Switch>
        <Route path="/projects/nagiostv">
          <div>
            NagiosTV
          </div>
        </Route>

        <Route path="/projects/yummiyogurt">
          <div>
            YummiYogurt
          </div>
        </Route>

        <Route path="/projects/:projectName">
          <div>
            Unknown Project
          </div>
        </Route>

        <Route path="/projects">
          <ProjectsIndex />
        </Route>
      </Switch>

        <br />

        <div>
          <Link to="/projects">Go back to projects</Link>
        </div>
        <div>
          <Link to="/">Go back to home</Link>
        </div>
        
        <br />

        {/*
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              fdsa
            </Grid.Column>
            <Grid.Column>
              2
            </Grid.Column>
            <Grid.Column>
              3
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              4
            </Grid.Column>
            <Grid.Column>
              5
            </Grid.Column>
            <Grid.Column>
              6
            </Grid.Column>
          </Grid.Row>
        </Grid>
        */}



        {/** 
    <div className="Main">

      
      
      abc

      <div className="header-after-area">
        Sort By:
        <select>
          <option selected>First Released</option>
          <option>Last Updated</option>
        </select>
      </div>

      <h2 className="ui dividing header margin-left-10" style={{textAlign: 'left'}}>
        <div className="content">
          Chris Carey
          <div style={{fontSize: '0.65em', fontWeight: '200'}}>Software Tools Engineer</div>
          <div style={{fontSize: '0.65em', fontWeight: '200'}}>Email chris chriscarey.com or chris sublimespot.com</div>
        </div>
      </h2>

      <Blog />

      <SublimeSpot />

      <NagiosTVNagios4 />

      <FlynnCSS />

        

      <h4 className="ui dividing header">
        <i className="paint brush icon"></i>
        <div className="content">
          Themes
        </div>
      </h4>
  
      <div className="ui three column doubling stackable grid container">
        <div className="column">
          <a href="https://sublimespot.com/graphics/skins/winamp/">
            <div>YummiYogurt for WinAMP and XMMS</div>
            <img style={{width: '100%'}} src="https://chriscarey.com/wp/wp-content/uploads/2013/12/yummiyogurt2001xmms.gif" />
            
          </a>
        </div>
        <div className="column" style={{maxHeight: '100%', overflow: 'hidden'}}>
          <a href="https://chriscarey.com/graphics/skins/gkrellm/">
            <div>YummiYogurt for GKrellM</div>
            <div style={{position: 'relative'}}>
              <img style={{width: '100%', opacity: '0', position: 'absolute'}} src="https://chriscarey.com/wp/wp-content/uploads/2013/12/yummiyogurt2001xmms.gif" />
              <img style={{}} src="https://chriscarey.com/graphics/skins/gkrellm/yummiyogurt-100px.png" />
            </div>
          </a>
        </div>
        <div className="column">
          <a href="https://sublimespot.com/graphics/skins/winamp/">
            <div>SublimeSpotAmp for WinAMP and XMMS</div>
            <img style={{width: '100%'}} src="https://sublimespot.com/graphics/skins/winamp/sublimespotamp/sublimespotamp_v021.gif" />
            
          </a>
        </div>
      </div>

      <div style={{margin: '0 10px', marginBottom: '10px'}}>

        <h4 className="ui dividing header">
          <i className="bicycle icon"></i>
          <div className="content">
            Older Projects
          </div>
        </h4>

        <div className="ui three column doubling stackable grid">
          <div className="column">
            <a href="https://chriscarey.com/blog/code/control-panel-control/">
              Control Panel Control
              <img style={{width: '100%'}} src="https://chriscarey.com/software/cpcontrol/images/cpcontrol.jpg" />
            </a>
            <div>1998</div>
          </div>
        </div>

        <h4 className="ui dividing header">
          <i className="phone icon"></i>
          <div className="content">
            Contact
          </div>
        </h4>


        <div className="text-align-left">
          Chris Carey<br />
          <a target="_blank" href="https://www.linkedin.com/in/chris-carey-1818b44/">LinkedIn</a><br />
          <a target="_blank" href="https://www.twitter.com/chriscareycode">Twitter</a>
        </div>

      </div>

      <div className="footer-area">
      </div>

      <div style={{height: '20px'}}>&nbsp;</div>

    </div>
    */}



    </div>
  );
  
};

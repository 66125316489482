import { useEffect, useState } from 'react';
import './doomguy.css';

const Doomguy = ({ cosmicDisplayMode }) => {
	
	const [doomguyClass, setDoomguyClass] = useState('doomguy doomguy1');

	const updateEverySeconds = 1;

	useEffect(() => {
		let count = 1;
		let h = setInterval(() => {
			if (count > 20) {
				count = 1;
			}
			setDoomguyClass('doomguy doomguy' + count);
			count = count + 1;
			//console.log('count is ' + count);
		}, updateEverySeconds * 1000);
		return () => {
			clearInterval(h);
		};
	}, [setDoomguyClass]);

	//console.log('cosmicDisplayMode is ' + cosmicDisplayMode);
	const showText = typeof cosmicDisplayMode === 'undefined' || cosmicDisplayMode === false;

	//const doomguyClass = 'doomguy doomguy1 inner'; //doomguy-spin for spin
	const doomguyHtml = `<div class="${doomguyClass}">`;
	return (
		<div className="doomguy-wrap">
			<div className={doomguyClass} />
			{showText && <div className="doomguy-html">{doomguyHtml}</div>}
		</div>
	);
	
}

export default Doomguy;

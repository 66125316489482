import React from 'react';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
import { useHistory, useLocation } from 'react-router-dom';
import './Menu.css';

export default function MainMenu() {

  let location = useLocation();
  //console.log('location is', location);
  //let { location } = useParams();
  //console.log({ location });
  let history = useHistory();
  //console.log({ history });

  /* eslint-disable no-unused-vars */
  //const [activeItem, setActiveItem] = React.useState('home');

  const handleItemClick = (e, props) => {
    //console.log('props is', props);
    //console.log('e.target is', e.target);
    const route = e.target.getAttribute('route');
    //console.log('route is', route);
    //setActiveItem(name);
    //this.props.changeActiveItem(name);
    //console.log(this.props.location);
    history.push(route);
  };

  const MenuItem = ({
    route,
    active,
    children,
  }) => {
    return (
    <div
      className={active ? 'menu-item menu-item-active' : 'menu-item'}
      route={route}
      onClick={handleItemClick}
    >
      {children}
    </div>
    );
  };
  
  return (
    <div className="Menu">
      <div className="menu-title">Chris Carey</div>

      <MenuItem
        active={location.pathname === '/'}
        route='/'
      >
        Home
      </MenuItem>

      {/* <MenuItem
        route='/projects'
        active={location.pathname.indexOf('/projects') === 0}
      >
        Projects
      </MenuItem> */}

      <div className="menu-item">
        <a href="/blog" style={{ color: 'inherit', textDecoration: 'none' }}>Blog</a>
      </div>

      {/* <MenuItem
        route='/blog'
        active={location.pathname === '/blog'}
      >
        Blog
      </MenuItem> */}
    </div>

    // <Menu>
    //   <Menu.Item
    //     name='CC'
    //     route='/'
    //     active={false}
    //     onClick={handleItemClick}
    //   />
    //   <Menu.Item
    //     name='home'
    //     route='/'
    //     active={location.pathname === '/'}
    //     onClick={handleItemClick}
    //   />
    //   <Menu.Item
    //     name='projects'
    //     route='/projects'
    //     active={location.pathname === '/projects'}
    //     onClick={handleItemClick}
    //   />
    //   <Menu.Item
    //     name='blog'
    //     route='/blog'
    //     active={location.pathname === '/blog'}
    //     onClick={() => window.location = 'https://chriscarey.com/blog/'}
    //   />
    // </Menu>
    
  );
}


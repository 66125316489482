import React, { useEffect } from 'react';

import {
  //BrowserRouter as Router,
  //Switch,
  //Route,
  Link
} from "react-router-dom";

import Doomguy from './doomguy/doomguy';
//import Webamp from './Webamp';
import WebampIframe from './WebampIframe';

import './Home.css';

function ExternalLink({
  href,
  children,
}) {
  return <a target="_blank" rel="noreferrer noopener" href={href}>{children}</a>;
}

export default function Home() {

  useEffect(() => {
    document.title = 'Chris Carey Home';
  }, []);

  

    // const zeroOne = [];
    // const rows = 10;
    // const cols = 80;
    // for (let i=0; i<rows; i++) {

    //   let row = '';
    //   for (let j=0; j<cols; j++) {
    //     row += Math.random()<0.5?0:1;
    //   }
    //   zeroOne.push(<div className="matrix">{row}</div>);
    // }

  const howManyYearsEngineer = new Date().getFullYear() - new Date(1995, 1, 1).getFullYear();
  const howManyYearsCode = new Date().getFullYear() - new Date(1985, 1, 1).getFullYear();

  return (
    <div className="Home">

      <div className="introduction">
        <div>
          {/* B.S. computer science, computer networking<br /> */}
          {/* {howManyYearsEngineer} years full-time software engineer<br /> */}
          {/* {howManyYearsCode} years writing code<br /> */}
          {/* 9 years Software Tools Engineer at Netflix<br /> */}
        </div>
      </div>

      <div className="section-container">

        <div className="section" style={{ minWidth: 'min(100vw, 500px)' }}>
          <div className="section-header">
            <h2>Cosmic Doomguy</h2>
          </div>
          <div className="section-margin">
            {/* <video src="https://chriscarey.com/video/pimoroni/doom-guy.mov" type="video/mp4"></video> */}

            <div>
              <ExternalLink href="https://en.wikipedia.org/wiki/Doomguy">Doomguy</ExternalLink>, the character from the original video game Doom (1993) for the <ExternalLink href="https://shop.pimoroni.com/products/cosmic-unicorn">Pimoroni Cosmic Unicorn</ExternalLink><br />
              <br />
              Doomguy gets more upset the more hosts are DOWN or services are WARNING or CRITICAL.<br />
              <br />
              Download the code here:<br />
              <ExternalLink href="https://github.com/chriscareycode/cosmic-unicorn/tree/main/cosmic-doomguy">Link to download Cosmic Doomguy on GitHub</ExternalLink>
            </div>

            <br />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: 90, backgroundColor: 'black', borderRadius: 16 }}>
                <Doomguy cosmicDisplayMode={true} />
              </div>
            </div>

            {/* <video width="360" controls>
              <source src="https://chriscarey.com/video/pimoroni/doomguy.mov" type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}

          </div>
            
          <div className="section-footer">2024</div>
        </div>

        <div className="section" style={{ minWidth: 'min(100vw, 500px)' }}>
          <div className="section-header">
            <h2>Cosmic Emoji</h2>
          </div>
          <div className="section-margin">
            <div style={{ fontSize: '2.5em'}}>
              ❤️ 🔥 🥰 🚀 😡 👾 🐢 ⚡️ 💸
            </div>
          <br />
There is something very nice about having super sized emojis<br />on display around your home or office.<br />
<br />
A project for the <ExternalLink href="https://shop.pimoroni.com/products/cosmic-unicorn">Pimoroni Cosmic Unicorn</ExternalLink> that allows you to paint emojis on them,<br />and control them from a computer, phone, or tablet.<br />
<br />
Written in TypeScript, React<br />
            <ExternalLink href="https://github.com/chriscareycode/cosmic-unicorn">Open source on GitHub</ExternalLink><br />
          </div>
          <br />
          <div style={{ backgroundColor: 'inherit' }}>
            <ExternalLink href="https://github.com/chriscareycode/cosmic-unicorn">
              <img style={{ border: '2px solid #444', maxWidth: 'min(70vw, 400px)', margin: '10px' }} src="https://chriscarey.com/images/pimoroni/unicorn/cosmic-emoji-2.jpeg" />
            </ExternalLink>
          </div>
          <div className="section-footer">2023</div>
        </div>


        <div className="section" style={{ minWidth: 'min(100vw, 500px)' }}>
          <div className="section-header">
            <h2>NagiosTV</h2>
          </div>
          <div className="section-margin">
            NagiosTV is a user interface for the Nagios monitoring system.<br />
            {/* <ExternalLink href="https://github.com/chriscareycode/nagiostv-react">Open source on GitHub</ExternalLink><br /> */}
            <br />
            <ExternalLink href="https://nagiostv.com">https://nagiostv.com</ExternalLink><br />
          </div>
          <br />
          <div style={{ backgroundColor: 'inherit' }}>
            {/** Link to nagiostv.com */}
            <ExternalLink href="https://nagiostv.com">
              <img style={{ border: '2px solid #444', maxWidth: 'min(70vw, 400px)', margin: '10px' }} src="images/nagiostv-0.8.5.png" />
            </ExternalLink>

            {/** Link to project page TODO*/}
            {/* <Link to="/projects/nagiostv">
              <img style={{ maxWidth: 'min(70vw, 400px)', margin: '10px' }} src="images/nagiostv-0.8.5.png" />
            </Link> */}

            {/** iFrame live demo */}
            {/* <iframe style={{ width: '90%', height: 500, fontSize: '0.7em' }} title="NagiosTV Demo" frameBorder="0" src="https://nagiostv.com/demo/"></iframe> */}
          </div>
          <div className="section-footer">2008 - 2023</div>
        </div>

        

        <div className="section">
          <div className="section-header">
            <h2>iOS / iPadOS User Interface in React</h2>
          </div>
          
          <div className="section-margin">
            iPhone UI elements and animations with React and Framer Motion<br />
            <ExternalLink href="https://chriscarey.com/projects/iphone-ui/">iPhone UI in React Demo</ExternalLink><br />
          </div>
          <br />
          <img style={{ maxWidth: 'min(70vw, 300px)', margin: '10px' }} src="images/iphone-ui-react.png" /><br />

          
          <div className="section-footer">2021</div>
        </div>

        <div className="section" style={{ maxWidth: 'min(500px, 100vw)' }}>
          <div className="section-header">
            <h2>Webamp drag across the EQ to change all values</h2>
            
          </div>
          <div>
            <div style={{ fontSize: '0.8em' }}>
              In Winamp you could mouse down on the first EQ VerticalSlider{' '}
              and drag the mouse across all of the VerticalSliders to set them all in one swipe.{' '}
              This PR brings the same capability to <ExternalLink href="https://webamp.org">Webamp</ExternalLink>.
              This has been merged <ExternalLink href="https://github.com/captbaritone/webamp/pull/1180">PR at GitHub</ExternalLink>
            </div>
            <br />
            <ExternalLink href="https://webamp.org">Webamp.org</ExternalLink><br />
            <br />
            <video
            autoPlay
            loop={true}
            src="https://user-images.githubusercontent.com/1002638/177918378-2615b514-f093-417a-85aa-0ad902d3c3e0.mov"
            data-canonical-src="https://user-images.githubusercontent.com/1002638/177918378-2615b514-f093-417a-85aa-0ad902d3c3e0.mov"
            poster="/images/winamp-pr-preview.png"
            controls="controls"
            muted="muted"
            className="d-block rounded-bottom-2 border-top width-fit"
            style={{ maxWidth: '360px', maxHeight:'640px' }}
            >
            </video>
          </div>
          
          <div className="section-footer">2022</div>
        </div>

        <div className="section">
          <div className="section-header">
            <h2>YummiYogurt 2001 Winamp Skin</h2>
          </div>
          <div className="webamp-section">
            A remaster of the original YummiYogurt skin<br />
            <br />
            {/* <Link to="/projects/yummiyogurt">
              <img src="images/yummiyogurt2001xmms-275x372.gif" />
            </Link> */}
            <div className="webamp-wrap">
              {/* <WebAmp skin={`https://s3-us-west-2.amazonaws.com/winamp2-js-skins/YummiYogurt_v5.wsz`} /> */}
              <WebampIframe src={`/webamp-yummiyogurt2001.html`} />
            </div>
          </div>
          <div className="section-footer">Created in 2001</div>
        </div>

        <div className="section">
          <div className="section-header">
            <h2>YummiYogurt Winamp Skin</h2>
          </div>
          <div className="webamp-section">
            Fun colorful Winamp skin. Featured in WIRED magazine Oct 2001<br />
            <br />
            {/* <Link to="/projects/yummiyogurt">
              <img src="images/YummiYogurt.gif" />
            </Link> */}
            <div className="webamp-wrap">
              {/* <WebAmp skin={`https://s3-us-west-2.amazonaws.com/winamp2-js-skins/YummiYogurt_v5.wsz`} /> */}
              <WebampIframe src={`/webamp-yummiyogurt.html`} />
            </div>
          </div>
          <div className="section-footer">Created in 1999</div>
        </div>

        <div className="section">
          <div className="section-header">
            <h2>SublimeSpotAmp Winamp Skin</h2>
          </div>
          <div className="webamp-section">
            First WinAMP skin<br />
            <br />
            {/* <Link to="/projects/yummiyogurt">
              <img src="https://chriscarey.com/wordpress/wp-content/uploads/2013/12/sublimespotamp_v021.gif" />
            </Link> */}
            <div className="webamp-wrap">
              {/* <WebAmp skin={`https://s3-us-west-2.amazonaws.com/winamp2-js-skins/YummiYogurt_v5.wsz`} /> */}
              <WebampIframe src={`/webamp-sublimespotamp.html`} />
            </div>
          </div>
          <div className="section-footer">Created in 1999</div>
        </div>

        <div className="section">
          <div className="section-header">
            <h2>SublimeSpot Website</h2>
          </div>
          <div>
            Fan site for the band Sublime<br />
            #1 result on Google for search term "sublime" for 10 years (1999-2009).<br />
            <ExternalLink href="https://sublimespot.com">https://sublimespot.com</ExternalLink><br />
            <br />
            <ExternalLink href="https://sublimespot.com">
            <img src="images/sublimespot.png" style={{ maxWidth: 'min(85vw, 500px)' }} />
            </ExternalLink>
           

          </div>
          <div className="section-footer">Created in 1996</div>
        </div>

        <div className="section">
          <div className="section-header">
            <h2>doomguy-css</h2>
          </div>
          <div style={{ minWidth: '200px', textAlign: 'center' }}>
            Doomguy from the 1993 video game Doom as a CSS sprite<br />
            <a href="https://github.com/chriscareycode/doomguy-css">Download on GitHub</a><br />
            <br />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: 320, backgroundColor: 'black', borderRadius: 16 }}>
                <Doomguy />
              </div>
            </div>

          </div>
          <div className="section-footer">2016</div>
        </div>

        <div className="section">
          <div className="section-header">
            <h2>Control Panel Control for Windows 95/98/ME</h2>
          </div>
          <div>
            <img loading="lazy" src="/images/cpcontrol.jpg" alt="Control Panel Control screenshot" /><br />

            <div style={{ maxWidth: '95%' }}>
              Software for Windows 95/98/ME (Released in 1998)<br />
              Control Panel Control allows you to access your Control Panels more quickly.<br />
              It places a drop-down Control Panels Folder in commonly used places,<br />
              such as your Start Menu, Desktop, or your Favorites folder.
            </div>
          </div>
          <div className="section-footer">Software for Windows 95/98/ME (Released in 1998)</div>
        </div>

        {/* <div className="section">
          <div className="section-header">
            <h2>Links</h2>
          </div>
          <div>
            Monitoring<br />
            <ExternalLink href="https://grafana.com/">Grafana</ExternalLink><br />
            <ExternalLink href="https://nagios.com/">Nagios</ExternalLink><br />
            <br />
            Home Automation<br />
            <ExternalLink href="https://nodered.org/">Node-RED</ExternalLink><br />
            <ExternalLink href="https://www.home-assistant.io/">Homeassistant</ExternalLink><br />
            <ExternalLink href="https://www.purpleair.com/">PurpleAir</ExternalLink><br />
            <br />
            Networking<br />
            <ExternalLink href="https://mikrotik.com/">MikroTik</ExternalLink><br />
            <ExternalLink href="https://wireguard.com/">WireGuard</ExternalLink><br />
            <br />
            Self Hosting<br />
            <ExternalLink href="https://bitwarden.com/">BitWarden</ExternalLink><br />
            <br />
            Voice Over IP<br />
            <ExternalLink href="https://freeswitch.com/">FreeSwitch</ExternalLink><br />
            <ExternalLink href="https://asterisk.com/">Asterisk</ExternalLink><br />
            <br />
            JavaScript<br />
            <ExternalLink href="https://reactjs.com/">React</ExternalLink><br />
            <ExternalLink href="https://nodejs.com/">Node.js</ExternalLink><br />
            <br />
            
            Raspberry Pi<br />
            <ExternalLink href="https://pi-hole.net/">PiHole</ExternalLink><br />
          </div>
        </div> */}

        {/* <div className="section">
          <div className="section-header">
            <h2>Contact</h2>
          </div>
          <div>
            <div>
              You can email me with my first name at this domain name:<br />
              <br />
              chris<br />
              @chriscarey.com
            </div>
            <br />
          </div>
        </div> */}

      </div>

      

      {/* <div style={{ margin: '10px 20px' }}>
        Contact
        <div>
          <ExternalLink href="https://twitter.com/chriscareycode">Twitter</ExternalLink>
        </div>        
      </div> */}

      {/* <h3>
        <Link to="/projects">Projects</Link>
      </h3>  */}

    </div>
  );
  
};
